import React from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
// import clsx from "clsx";
import SEO from "../components/seo";
// import Slider from "../components/slices/Slider";
import ImagesGrid from "../components/slices/ImagesGrid";
import ArticlesGrid from "../components/slices/ArticlesGrid";
import Texte from "../components/slices/Texte";
import Archive from "../components/slices/Archive";

export const pageQuery = graphql`
  query PageBySlug($uid: String!, $lang: String!) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      data {
        home_page
        title {
          text
        }
        meta_title
        meta_image {
          url
        }
        meta_description {
          text
        }
        body {
          ... on PrismicPageBodyImagesGrid {
            slice_type
            items {
              image {
                ...sharp
              }
            }
          }
          ... on PrismicPageBodyArticlesGrid {
            slice_type
            items {
              article {
                type
                uid
                lang
                document {
                  ... on PrismicArticle {
                    data {
                      image {
                        ...sharp
                      }
                    }
                  }
                }
              }
            }
          }

          ... on PrismicPageBodyTexte {
            slice_type
            primary {
              texte {
                raw
                html
              }
            }
          }
          ... on PrismicPageBodyArchive {
            slice_type
            items {
              article {
                document {
                  ... on PrismicArticle {
                    uid
                    data {
                      title {
                        text
                      }
                      image {
                        ...sharp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TemplatePage = ({ data }) => {
  // const { template } = useContext(WrapperContext);
  const { uid } = data.prismicPage;
  const {
    home_page,
    title,
    meta_title = "",
    meta_description = {},
    meta_image = {},
    body,
  } = data.prismicPage.data;
  // console.log(data);
  // useEffect(() => {}, []);

  const slices = body.map((slice, i) => {
    // console.log(slice.slice_type);
    switch (slice.slice_type) {
      // case "images_grid":
      //   return <ImagesGrid key={i} input={slice.items} />;
      case "articles_grid":
        return <ArticlesGrid key={i} input={slice.items} />;
      case "archive":
        return <Archive key={i} input={slice.items} />;
      case "texte":
        return <Texte key={i} input={slice.primary.texte} />;
      default:
        return null;
    }
  });

  const isHomeOrAbout =
    ["about", "a-propos", "home"].indexOf(data.prismicPage.uid) >= 0;
  // console.log("isHomeOrAbout", isHomeOrAbout, data.prismicPage.uid);
  return (
    <div className="wrapper">
      <SEO
        pageTitle={meta_title}
        pageDescription={meta_description.text}
        pageImage={
          meta_image && meta_image.url
            ? meta_image.url
            : "https://images.prismic.io/baekbooks/7a2100c5-70df-48fe-9353-207f62d8669c_carte.png?auto=compress%2Cformat"
        }
        template={`template-page template-${uid}`}
        page={!home_page}
      />

      <div className="slices">{slices}</div>

      {isHomeOrAbout && (
        <div className="row">
          <div className="col-md-10  col-xs-12">
            <div className="text-right">
              <img
                className="carte-visite inline-block"
                src={meta_image.url}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withPreview(TemplatePage);
