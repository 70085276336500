import React, { useEffect } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Archive = ({ input }) => {
  // console.log(input);
  // const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // if (window.innerWidth < 1024 && "ontouchstart" in window) setIsMobile(true);
  }, []);

  // const { image_clef, sta, cat, abc } = input.data;
  // console.log(image_clef);
  return (
    <div className="archive">
      <div className="row bottom-xs">
        {input.map(({ article }, i) => (
          <div className="col-md-3 col-xs-12 mb-md" key={i}>
            <Link to={`/a/${article.document.uid}`}>
              {/* {JSON.stringify(article)} */}
              <Img {...article.document.data.image} />
            </Link>
          </div>
        ))}
        {/* {input.map(({ article }, i) => (
          <div className="col-md-3 col-xs-12 mb-md" key={i}>
            <Link to={`/a/${article.document.uid}`}>
              <Img {...article.document.data.image} />
            </Link>
          </div>
        ))}
        {input.map(({ article }, i) => (
          <div className="col-md-3 col-xs-12 mb-md" key={i}>
            <Link to={`/a/${article.document.uid}`}>
              <Img {...article.document.data.image} />
            </Link>
          </div>
        ))}
        {input.map(({ article }, i) => (
          <div className="col-md-3 col-xs-12 mb-md" key={i}>
            <Link to={`/a/${article.document.uid}`}>
              <Img {...article.document.data.image} />
            </Link>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Archive;
