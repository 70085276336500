import React, { useEffect, useRef, useState } from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { minMax, randomizePositions } from "../../core/utils";

const Wrapper = styled.div`
  // position: absolute;
`;

const ImagesGridCard = ({ input, i }) => {
  const [css, setCss] = useState({});
  const cardRef = useRef();

  useEffect(() => {
    // if (window.innerWidth > 768) {
    const style = _randomizePositions();
    setCss(style);
    // }
  }, [i]);

  const _randomizePositions = () => {
    //if(!this.refs.figure) return {}
    // const { isAnimated } = this.state;
    // if (isAnimated) return {};
    const radius = window.innerWidth > 768 ? 25 : 5;
    const randX = minMax(Math.random() * -radius, Math.random() * radius);
    const randY = minMax(Math.random() * -radius, Math.random() * radius);
    // const { i } = props;
    return {
      transform: `translate(${randX}%, ${randY}%)`
    };

    return randomizePositions(i, cardRef.current);
  };

  return (
    <Wrapper
      className='card col-md-3 col-xs-12 mb-md'
      ref={cardRef}
      style={css}>
      <figure>
        <div className='outter a-r-a4'>
          <div className='inner x xdc xjc'>
            <Img {...input.image} />
            {/* <pre>{JSON.stringify(el)}</pre> */}
          </div>
        </div>
      </figure>
    </Wrapper>
  );
};

export default ImagesGridCard;
