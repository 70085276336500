import React from "react";
import { Link } from "gatsby";
import { _localizedSlug } from "../../core/utils";
import Card from "./ArticlesGridCard";

const ArticlesGrid = ({ input }) => (
  <div className="images-grid">
    <div className="row">
      <div className="col-md-10 col-xs-12">
        <div className="row">
          {input.map(({ article }, i) => (
            <Card key={i} input={{ article, i }} />
          ))}
        </div>
      </div>
      <div className="col-md-2 hidden-xs"></div>
    </div>
  </div>
);

export default ArticlesGrid;
