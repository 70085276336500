import React from "react";
import { RichText } from "prismic-reactjs";

const Texte = ({ input }) => {
  // const { texte } = input.primary;
  // const images = items.map((el) => el.image);
  // console.log(input);
  // return null;
  return <div className="texte">{RichText.render(input.raw)}</div>;
};

export default Texte;
